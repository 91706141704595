.tile span[data-animation='pop'] {
  animation-name: PopIn;
  animation-duration: 300ms;
}

@keyframes PopIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }

  40% {
    transform: scale(1.1);
    opacity: 1;
  }
}

.tile[data-animation='flip-in'] {
  animation-name: FlipIn;
  animation-duration: 300ms;
  animation-timing-function: ease-in;
}
@keyframes FlipIn {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(-90deg);
  }
}
.tile[data-animation='flip-out'] {
  animation-name: FlipOut;
  animation-duration: 300ms;
  animation-timing-function: ease-in;
}
@keyframes FlipOut {
  0% {
    transform: rotateY(-90deg);
  }
  100% {
    transform: rotateY(0);
  }
}
